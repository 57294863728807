<template>
  <v-container fluid>
    <v-breadcrumbs
      :items="pathModul"
      divider="-"
      normal
      class="pathModuls"
    ></v-breadcrumbs>

    <v-container fluid :class="$vuetify.breakpoint.lgAndUp ? 'px-6' : 'px-1'">
      <v-row align="center">
        <!-- Column Select Station -->
        <v-col class="col-input-filter">
          <v-select
            v-on:change="selectStation"
            :items="itemStation"
            item-value="station_id"
            item-text="name"
            label="Station"
            prepend-inner-icon="mdi-store-marker"
            dense
            solo
          ></v-select>
        </v-col>

        <!-- Column Select Calendar -->
        <v-col class="col-input-filter">
          <v-menu
            v-model="modalCalendar"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="valueFromDateep"
                prefix="From :"
                prepend-inner-icon="mdi-calendar-start"
                readonly
                v-bind="attrs"
                v-on="on"
                dense
                solo
              >
              </v-text-field>
            </template>
            <v-date-picker
              v-model="valueFromDateep"
              v-on:change="selectFromDate"
              @input="modalCalendar = false"
            >
              <v-spacer></v-spacer>
            </v-date-picker>
          </v-menu>
        </v-col>

        <!-- Column Select Calendar2 -->
        <v-col class="col-input-filter">
          <v-menu
            v-model="modalCalendar2"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="valueToDateep"
                prefix="To :"
                prepend-inner-icon="mdi-calendar-end"
                readonly
                v-bind="attrs"
                v-on="on"
                dense
                solo
              >
              </v-text-field>
            </template>
            <v-date-picker
              v-model="valueToDateep"
              v-on:change="selectToDate"
              @input="modalCalendar2 = false"
            >
              <v-spacer></v-spacer>
            </v-date-picker>
          </v-menu>
        </v-col>

        <!-- Column Generate Button -->
        <v-col cols="12" sm="6" md="4">
          <v-btn
            class="white--text button mr-2 mb-5"
            solo
            dense
            normal
            :disabled="valueStationep == null"
            @click="getExceedanceParam"
          >
            GENERATE REPORT
          </v-btn>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
              color="excel"
              class="mb-5"
              style="float: right;"
              elevation="2"
              v-bind="attrs"
              v-on="on"
              @click="exportReport"
              :disabled="expIcon == null"
              >
                <v-icon
                color="white"
                center
                dark
                >
                    mdi-microsoft-excel
                </v-icon>
              </v-btn>
            </template>
            <span>Export to Excel</span>
          </v-tooltip>
          <!-- <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
              color="pdf"
              class="mb-5 mx-2"
              style="float: right;"
              elevation="2"
              v-bind="attrs"
              v-on="on"
              @click="exportReport('pdf')"
              :disabled="expIcon == null"
              >
                <v-icon
                color="white"
                center
                dark
                >
                    mdi-file-pdf-box
                </v-icon>
              </v-btn>
            </template>
            <span>Export to PDF</span>
          </v-tooltip> -->

          

          <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
              <v-btn
              color="csv"
              class="mb-5 mx-2"
              style="float: right;"
              elevation="2"
              v-bind="attrs"
              v-on="on"
              @click="exportCSV()"
              :disabled="expIcon == null"
              >
                <v-icon
                color="white"
                center
                dark
                >
                    mdi-file-delimited
                </v-icon>
              </v-btn>
            </template>
            <span>Export to CSV</span>
          </v-tooltip>
        </v-col>
      </v-row>

      <v-card class="pa-4" style="max-height: calc(100vh - 230px);overflow-y:auto;">
        <v-subheader
          class="subTitles fontSubTitle-blue"
          v-if="queryValueep == true"
        >
          <!-- EXCEEDANCE BY PARAMETER ALERT RECORD FOR {{ valueStationep }} ({{ valueFromDateep }} TO {{ valueToDateep }}) -->
          Exceedance By Parameter Alert Record For {{ valueStationep }} ({{
            this.changeDateFormat(valueFromDateep)
          }}
          To {{ this.changeDateFormat(valueToDateep) }})
        </v-subheader>

        <v-data-table
          :headers="thead_alertexceedanceByParameter"
          :items="tbody_alertexceedanceByParameter"
          :items-per-page="5"
          class="elevation-1 headerDtSarawak"
          :custom-sort="customSort"
          :loading="loadingTable"
        >
          <!-- <template v-slot:header="{ props: { headers } }">
              <thead>
                  <tr>
                      <th v-for="(h,key) in headers" 
                      :key="key"
                      divider='true'
                      class="table-header">
                      <span>{{h.text}}</span>
                      </th>
                  </tr>
              </thead>
          </template> -->

          <template v-slot:[`item.datetime_ui`]="props">
            
              <span>{{ useConvertDisplayDatetime(props.item.datetime_ui) }}</span>
             
          </template>

          
          <template v-slot:[`item.notesupdateddt_ui`]="props">
            
            <span>{{ useConvertDisplayDatetime(props.item.notesupdateddt_ui) }}</span>
           
        </template>

          <template v-slot:[`item.notes`]="props">
            <v-edit-dialog
              :return-value.sync="props.item.notes"
              large
              persistent
              @save="saveExceedParam"
              @cancel="cancelExceedParam"
              @open="openExceedParam"
              @close="closeExceedParam"
            >
              <div>{{ props.item.notes }}</div>
              <template v-slot:input>
                <div class="mt-4 text-h6">
                  Update Notes
                </div>
                <v-text-field
                  v-model="props.item.notes"
                  label="Edit"
                  single-line
                  counter
                  autofocus
                  @input="typingNotes(props.item,$event)"
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>
        </v-data-table>

        <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
          {{ snackText }}

          <template v-slot:action="{ attrs }">
            <v-btn v-bind="attrs" text @click="snack = false">
              Close
            </v-btn>
          </template>
        </v-snackbar>
      </v-card>
    </v-container>
  </v-container>
</template>

<script>
import axios from "axios";
// import XLSX from 'xlsx';
import Papa from "papaparse";

export default {
  data: () => ({
    pathModul: [
      {
        text: "Home",
        disabled: false,
        // href: "/",
        href: "/Mapviewer",
      },
      {
        text: "Alert - Exceedance By Parameter Alert Record",
        disabled: true,
        href: "/Alert/ExceedanceByParameter",
      },
    ],
    itemStation: [],
    expIcon: null,
    // date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    // date2: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    menu: false,
    modalCalendar: false,
    menu2: false,
    modalCalendar2: false,

    loadingTable: false,
    thead_alertexceedanceByParameter: [
      { text: "STATION ID", value: "stationid", divider: true, align: "center", sortable: false, },
      { text: "DATETIME", value: "datetime_ui", divider: true, align: "center" },
      { text: "EXCEEDANCE", value: "paramselected", divider: true, align: "center", },
      { text: "NOTES", value: "notes", divider: true, align: "center", sortable: false, },
      { text: "UPDATED BY", value: "notesby", divider: true, align: "center", sortable: false, },
      { text: "UPDATED ON", value: "notesupdateddt_ui", divider: true, align: "center", },
    ],
    tbody_alertexceedanceByParameter: [],

    valueStationep: null,
    valueFromDateep: new Date(
      Date.now() - new Date().getTimezoneOffset() * 60000
    )
      .toISOString()
      .substr(0, 10),
    valueToDateep: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    queryValueep: false,

    snack: false,
    snackColor: "",
    snackText: "",
    pagination: {},
    updateNotesExceedParam: {
      newId: "",
      notes: "",
    },
  }),

  methods: {
    selectStation(stationId) {
      // console.log(stationId);
      this.valueStationep = stationId;
    },

    selectFromDate(fromDate) {
      // console.log(fromDate);
      this.valueFromDateep = fromDate;
    },

    selectToDate(toDate) {
      // console.log(toDate);
      this.valueToDateep = toDate;
    },

    changeDateFormat(date) {
      var dd = date.split("-")[2];
      var mm = date.split("-")[1];
      var yyyy = date.split("-")[0];
      date = dd + "/" + mm + "/" + yyyy;
      return date;
    },

    changeDatetimeFormat(datetime) {
      if (datetime != "" && datetime != null) {
        let [date, time] = datetime.split("T");

        //convert date
        var dd = date.split("-")[2];
        var mm = date.split("-")[1];
        var yyyy = date.split("-")[0];
        date = dd + "/" + mm + "/" + yyyy;

        // Convert Time to 12 hours
        var H = +time.substr(0, 2);
        var h = H % 12 || 12;
        var ampm = H < 12 ? "AM" : "PM";
        time = h + time.substr(2, 3) + time.substr(5, 3) + ampm;

        datetime = date + ", " + time;
        return datetime;
      } else {
        return (datetime = null);
      }
    },

    getStationExceedanceParam() {
      axios
        .get(this.globalUrl+"mqm2/miqims/stations", {
          headers: {
            Authorization: "Bearer " + this.$store.getters.user.token,
          },
        })
        .then((response) => {
          let stationInfo = [];
          // let stationInfo = [];

          response.data.forEach(function(res) {
            let objStation = {};
            objStation["station_id"] = res.STATION_ID;
            objStation["name"] = res.STATION_ID + " - " + res.LOCATION;
            stationInfo.push(objStation);
          });

          this.itemStation = stationInfo;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getExceedanceParam() {
      this.loadingTable = true;
      this.tbody_alertexceedanceByParameter = [];
      this.expIcon = "1";
      axios
        .get(
          this.globalUrl+"mqm2/miqims/exceedance?station=" +
            this.valueStationep +
            "&startdt=" +
            this.valueFromDateep +
            "&enddt=" +
            this.valueToDateep,
          {
            headers: {
              Authorization: "Bearer " + this.$store.getters.user.token,
            },
          }
        )
        .then((response) => {

          let displayData = [];

          let data = response.data;

          // data.forEach(function(res) {
          //   var match = res.paramSelected.split("|");
          //   var categories = res.category;
          //   if (match.length == 1 && categories == "PARAM") {
          //     displayData.push(res);
          //   }
          //   // if(match.length == 1){
          //   //     displayData.push(res);
          //   // }
          // });

          // console.log(data[0]["datetime"], "aa")

          for (let i in data){
            // data[i]["datetime_ui"] = this.useConvertDisplayDatetime(data[i]["datetime"])
            // data[i]["notesupdateddt_ui"] = this.useConvertDisplayDatetime(data[i]["notesupdateddt"])
            data[i]["datetime_ui"] = data[i]["datetime"]
            data[i]["notesupdateddt_ui"] = data[i]["notesupdateddt"]
          }

          this.tbody_alertexceedanceByParameter = data;
          // for (let i = 0; i < displayData.length; i++) {
          //   displayData[i].datetime = this.changeDatetimeFormat(
          //     displayData[i].datetime
          //   );
          //   displayData[i].notesUpdatedDt = this.changeDatetimeFormat(
          //     displayData[i].notesUpdatedDt
          //   );
          // }

          //   this.tbody_alertexceedanceByParameter = response.data;
          //   for (let i=0; i < response.data.length; i++){
          //     response.data[i].datetime = this.changeDatetimeFormat(response.data[i].datetime);
          //     response.data[i].notesUpdatedDt = this.changeDatetimeFormat(response.data[i].notesUpdatedDt);
          //   }
          this.queryValueep = true;
          this.loadingTable = false;
        })
        .catch((error) => {
          console.log(error);
          this.loadingTable = false;
        });
    },

      exportCSV(){

        // console.log(this.tableCon)
        let headerExportCsv = [];
        for(let i in this.thead_alertexceedanceByParameter){
          headerExportCsv.push(this.thead_alertexceedanceByParameter[i]['value']);
        }

        let keys = headerExportCsv,
        resultAfterFilter = this.tbody_alertexceedanceByParameter.map(o => Object.assign(...keys.map(k => ({ [k]: o[k] }))));

        let blob = new Blob([Papa.unparse(resultAfterFilter)], { type: 'text/csv;charset=utf-8;' });

        let link = document.createElement("a");

        let url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", 'Alert - Exceedance By Parameter Alert Record.csv');
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        },

        exportReport(){


        // Define your style class template.

        let headerName = "<tr = 'border:none !important' ><td colspan ='12' style = 'text-align: center; border:none !important; font-weight: bold'>Exceedance By Parameter Alert Record</td></tr>"
        var style = "<style> th, td { border: 10px solid;}</style>";

        var uri = 'data:application/vnd.ms-excel;base64,'
            , template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]-->' + style + '</head><body><table>' + headerName + '{table}</table></body></html>'
            , base64 = function (s) {
                return window.btoa(unescape(encodeURIComponent(s)))
            }
            , format = function (s, c) {
                return s.replace(/{(\w+)}/g, function (m, p) { return c[p]; })
            }

            let table = document.querySelectorAll('.v-data-table__wrapper ')
            console.log("hehe", table[0].innerHTML, table, document.querySelectorAll('.v-data-table__wrapper table'))
            let ctx = { worksheet: "Exceedance By Parameter Alert Record" || 'Worksheet', table: table[0].innerHTML }
            // window.location.href = uri + base64(format(template, ctx))

            let a = document.createElement('a');
            a.href = uri + base64(format(template, ctx))
            a.download = 'Exceedance By Parameter Alert Record.xls';
            //triggering the function
            a.click();
        },

    // open modal notes
    typingNotes(data,notes){

      // console.log(data);
      // console.log(notes);

      this.updateNotesExceedParam.newId = data.newid
      this.updateNotesExceedParam.notes = notes

      console.log(this.updateNotesExceedParam);

    },
    saveExceedParam() {
      const that = this;
      axios
        .put(
          this.globalUrl+`mqm2/miqims/exceedance`,
          this.updateNotesExceedParam,
          {
            headers: {
              Authorization: "Bearer " + this.$store.getters.user.token,
            },
          }
        )
        .then((response) => {
          console.log(response.data);
          that.getExceedanceParam();
        })
        .catch((error) => {
          console.log(error);
        });
      console.log(this.updateNotesExceedParam);
      this.snack = true;
      this.snackColor = "success";
      this.snackText = "Data saved";
    },
    cancelExceedParam() {
      this.snack = true;
      this.snackColor = "error";
      this.snackText = "Canceled";
    },
    openExceedParam() {
      this.snack = true;
      this.snackColor = "info";
      this.snackText = "Dialog opened";
    },
    closeExceedParam() {
      console.log("Dialog closed");
    },

    exportReportExceedParam() {
      // const data = XLSX.utils.json_to_sheet(this.tbody_alertexceedanceByParameter);
      // const wb = XLSX.utils.book_new();
      // XLSX.utils.book_append_sheet(wb, data, 'data');
      // XLSX.writeFile(wb,'Exceedance By Parameter Alert Record For ' + this.valueStationep + ' (' + this.changeDateFormat(this.valueFromDateep) + ' TO ' + this.changeDateFormat(this.valueToDateep) + ').xlsx');
      // console.log(this.tbody_alertexceedanceByParameter);
      let headerExceedParam = [];
      for (let i = 0; i < this.thead_alertexceedanceByParameter.length; i++) {
        headerExceedParam.push(this.thead_alertexceedanceByParameter[i].value);
      }

      let keysExceedParam = headerExceedParam,
        result = this.tbody_alertexceedanceByParameter.map((o) =>
          Object.assign(...keysExceedParam.map((k) => ({ [k]: o[k] })))
        );

      console.log(result);

      var blob = new Blob([Papa.unparse(result)], {
        type: "text/csv;charset=utf-8;",
      });

      var link = document.createElement("a");

      var url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute(
        "download",
        "Exceedance By Parameter Alert Record For " +
          this.valueStationep +
          " (" +
          this.changeDateFormat(this.valueFromDateep) +
          " TO " +
          this.changeDateFormat(this.valueToDateep) +
          ").csv"
      );
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    customSort: function(items, index, isDesc) {
      //   console.log(items, index, isDesc);

      //   console.log(index);

      items.sort((a, b) => {
        if (index[0] == "datetime" || index[0] == "notesUpdatedDt") {
          //   console.log(new Date(this.standardDtFormatNuzul(b[index])));
          if (!isDesc[0]) {
            return (
              new Date(this.standardDtFormatNuzul(b[index])) -
              new Date(this.standardDtFormatNuzul(a[index]))
            );
          } else {
            return (
              new Date(this.standardDtFormatNuzul(a[index])) -
              new Date(this.standardDtFormatNuzul(b[index]))
            );
          }
        } else {
          if (typeof a[index] !== "undefined") {
            if (!isDesc[0]) {
              return a[index]
                .toLowerCase()
                .localeCompare(b[index].toLowerCase());
            } else {
              return b[index]
                .toLowerCase()
                .localeCompare(a[index].toLowerCase());
            }
          }
        }
      });
      return items;
    },
  },

  mounted() {
    this.getStationExceedanceParam();
  },
};
</script>

<style lang="scss">
@import "~scss/main";

.pathModuls {
  padding-bottom: 0px;
}

.pathModuls > li:nth-child(3) > a {
  color: black !important;
}

.container-filtering {
  padding: 0px;
}

.col-input-filter {
  padding: 0px 10px;
}

.col-btn-generateReport {
  margin-bottom: 18px;
}

.btn-generate {
  margin: 0px !important;
  // background: #4495D1 !important;
}

.container-filtering::v-deep .v-text-field.v-text-field--solo .v-input__control,
.btn-generate .v-btn .v-btn--contained {
  min-height: 10px;
  margin-top: 10px;
}

.subTitles {
  margin-left: 28%;
  // color: #1976D2 !important;
}

// .table-header {
//     white-space: nowrap;
//     padding: 16px !important;
//     // background: #4495D1;
//     // color: white !important;
//     text-align: center !important;
//     border: 1px solid rgba(0, 0, 0, 0.12);
// }
</style>
